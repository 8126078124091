<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">THÔNG TIN LÁI XE</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            /></div
                    ></slot>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content :scroll-events="false">
            <ThongTinLaiXeVue
                :action="action"
                ref="ThongTinLaiXe"
                :isValidSoDienThoai="isValidSoDienThoai"
                :TaiKhoanDaTonTai="TaiKhoanDaTonTai"
                :LayChiTietThanhCong="LayChiTietThanhCong"
                @showPopup="HienThiPopupDatMatKhauHoacTaoTaiKhoan"
                @LuuThongTin="LuuThongTin"
                @getData="getData"
            />
        </ion-content>
    </ion-page>
</template>
<script>
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    onIonViewWillLeave,
    onIonViewWillEnter,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import ThongTinLaiXeVue from "./components/ThongTinLaiXe.vue";
export default {
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonPage,
        DxButton,
        ThongTinLaiXeVue,
    },
    data() {
        return {
            action: "Add",
            IdLaiXe: null,
            LayChiTietThanhCong: false,
        };
    },
    computed: {
        ThongTinLaiXe: {
            get() {
                return this.$store.state.ThongTinLaiXe.ThongTinLaiXe;
            },
            set(data) {
                this.$store.commit("ThongTinLaiXe/Set", {
                    key: "ThongTinLaiXe",
                    data: data,
                });
            },
        },
        DanhSachTinhThanh() {
            return this.$store.state.ThongTinHeThong.DanhSachTinhThanh;
        },
        CheckTaoTaiKhoan: {
            get() {
                return this.$store.state.TaiKhoanLaiXe.CheckTaoTaiKhoan;
            },
            set(value) {
                this.$store.commit("TaiKhoanLaiXe/Set", {
                    key: "CheckTaoTaiKhoan",
                    data: value,
                });
            },
        },
    },
    methods: {
        getData() {
            let query = this.$route.query;
            if (query.IdLaiXe) {
                this.IdLaiXe = query.IdLaiXe;
                this.action = "Edit";
                this.layChiTietLaiXe(query.IdLaiXe);
                this.getDanhSachLoaiBen();
                this.getDanhSachTinhThanh();
                this.getDanhSachHangGPLX();
            } else {
                this.getDanhSachLoaiBen();
                this.getDanhSachTinhThanh();
                this.getDanhSachHangGPLX();
            }
        },
        async layChiTietLaiXe(idLaiXe) {
            try {
                this.LayChiTietThanhCong = false;
                let rs = await this.$store.dispatch(
                    "ThongTinLaiXe/Get_ChiTietLaiXe",
                    idLaiXe,
                );
                if (rs.status == false) {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Lấy chi tiết lái xe thất bại!",
                        message: rs.message,
                    });
                } else {
                    this.LayChiTietThanhCong = true;
                }
            } catch (ex) {
                console.log("🚀 ~ file: index.vue:106 ~ layChiTietLaiXe ~ ex:", ex);
                this.$Helper.ThongBaoPopup({
                    message_title: "Lấy chi tiết lái xe thất bại!",
                    message: "Lỗi hệ thống client.",
                });
            }
        },
        async getDanhSachLoaiBen() {
            try {
                await this.$store.dispatch("ThongTinBenXe/Get_DanhSachLoaiBen");
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách loại bến thất bại! Lỗi hệ thống client.`,
                );
            }
        },
        async getDanhSachTinhThanh() {
            try {
                await this.$store.dispatch("ThongTinHeThong/Get_DanhSachTinhThanh");
                if (this.action == "Add") {
                    this.ThongTinLaiXe.TinhThanhPho =
                        this.DanhSachTinhThanh.length > 0
                            ? this.DanhSachTinhThanh[0]?.ID_Tinh
                            : null;
                }
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách tỉnh thành thất bại! Lỗi hệ thống client.`,
                );
            }
        },
        async getDanhSachHangGPLX() {
            try {
                await this.$store.dispatch("ThongTinLaiXe/Get_DanhSachHangGPLX");
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách hạng bằng thất bại! Lỗi hệ thống client.`,
                );
            }
        },
        async LuuThongTin() {
            this.TaoTaiKhoanSua = false;
            this.SuaTaiKhoan = false;
            try {
                let self = this;
                let rs;
                if (this.action == "Add") {
                    rs = await this.$store.dispatch("ThongTinLaiXe/ThemLaiXe");
                } else if (this.action == "Edit") {
                    rs = await this.$store.dispatch(
                        "ThongTinLaiXe/SuaLaiXe",
                        self.IdLaiXe,
                    );
                }
                if (rs.status == true) {
                    this.$Helper.ThongBaoToast(
                        "success",
                        `Lưu thông tin lái xe thành công!`,
                    );
                    if (this.action == "Add" && this.CheckTaoTaiKhoan) {
                        this.$refs.ThongTinLaiXe.showPopup("PopupThongTinTaiKhoanLaiXe");
                        return;
                    }

                    if (
                        this.action == "Edit" &&
                        this.SoDienThoaiGoc != this.ThongTinLaiXe.SoDienThoai
                    ) {
                        this.TaoTaiKhoanSua = true;
                    } else if (this.action == "Edit") {
                        this.SuaTaiKhoan = true;
                    }
                    if (this.action == "Add") {
                        this.$router.go(-1);
                        return;
                    }
                    this.getData();
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Lưu thông tin lái xe thất bại!",
                        message: rs.message,
                    });
                }
            } catch (ex) {
                console.log("🚀 ~ file: index.vue ~ line 137 ~ LuuThemMoi ~ ex", ex);
                this.$Helper.ThongBaoPopup({
                    message_title: "Lưu thông tin lái xe thất bại!",
                    message: "Lỗi hệ thống client.",
                });
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.getData();
        });
    },
    async mounted() {
        onIonViewWillLeave(() => {
            this.$store.dispatch("ThongTinLaiXe/ResetThongTinLaiXe");
        });
    },
    watch: {},
};
</script>
